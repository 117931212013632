<template>
  <div id="filters">
    <b-card>
      <b-row>
        <b-col cols="12" lg="6">
          <label>Search</label>
          <b-form-input type="text" placeholder="Search Products here" v-model="searchQuery" />
        </b-col>
        <b-col cols="12" lg="4">
          <label for="user">User</label>
          <v-select :options="users" @search="searchUsers" v-model="user"></v-select>
        </b-col>
        <b-col cols="12" lg="2">
          <label for="status">Status</label>
          <v-select :options="statuses" v-model="isActive" />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="mb-1 border-bottom"
        ref="refProductsTable"
        :fields="tableCols"
        :items="loadProducts"
        show-empty
        :empty-html="isLoading ? 'Loading...' : 'No data found'"
      >
        <template #cell(user)="data">
          <span :class="{ 'text-muted': !data.item.userId }">{{
            data.item.userId ? data.item.userId.username : "n/a"
          }}</span>
        </template>
        <template #cell(category)="data">{{ data.item.category.name }}</template>

        <template #cell(price)="data">
          {{
            data.item.price
              ? `${formatNumber(data.item.price.priceNumber)} ${
                  data.item.price.currencyId ? data.item.price.currencyId.short_name : ""
                }`
              : "n/a"
          }}
        </template>

        <template #cell(created)="data">{{
          moment(data.item.created_at).format("DD/MM/Y")
        }}</template>
        <template #cell(status)="data">
          <b-form-checkbox
            v-model="data.item.isActive"
            class="custom-control-success"
            switch
            size="lg"
            @change="updateProfileStatus(data.item._id, $event)"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormInput,
  BTable,
  BFormCheckbox,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useProducts from "./useProducts";
import useHelper from "@/helper";
import moment from "moment";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BTable,
    vSelect,
    BFormCheckbox,
    BPagination,
  },
  setup() {
    const {
      tableCols,
      loadProducts,
      refProductsTable,
      perPageOptions,
      perPage,
      currentPage,
      searchQuery,
      user,
      users,
      isActive,
      statuses,
      searchUsers,
      dataMeta,
      totalProducts,
      updateProfileStatus,
      isLoading,
    } = useProducts();

    const { formatNumber } = useHelper();

    return {
      tableCols,
      loadProducts,
      refProductsTable,
      perPageOptions,
      perPage,
      currentPage,
      moment,
      searchQuery,
      user,
      users,
      isActive,
      statuses,
      searchUsers,
      dataMeta,
      totalProducts,
      updateProfileStatus,
      formatNumber,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
