export default function () {
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getProfileTypeCoverImage = (files) => {
    // check if there are items in the files
    if (!files.length) {
      return;
    }
    const file = files.find((x) => {
      return x.name == "Profile Icon";
    });
    return file;
  };

  const getProfileUrl = (type, id) => {
    const profileType = type == "idea" ? "ideas" : type;
    return `/${profileType}/details/${id}`;
  };

  return {
    formatNumber,
    getProfileTypeCoverImage,
    getProfileUrl,
  };
}
