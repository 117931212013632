import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useProducts() {
  const toast = useToast();

  const users = ref([]);
  const refProductsTable = ref(null);

  const perPage = ref(10);
  const perPageOptions = [10, 25, 50, 100];
  const currentPage = ref(1);

  const user = ref("");
  const searchQuery = ref("");
  const isActive = ref("");
  const totalProducts = ref(0);
  const isLoading = ref(false);

  /* Status*/
  const statuses = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  /* Tabel Colums */
  const tableCols = [
    { key: "name" },
    { key: "user" },
    { key: "Category" },
    { key: "Price" },
    { key: "Created" },
    { key: "Status" },
  ];

  /* Load IDeas  */
  const loadProducts = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("type", "product");
      formData.append("perPage", perPage.value);
      formData.append("q", searchQuery.value);
      formData.append("userId", user.value ? user.value.value : "");
      formData.append("isActive", isActive.value ? isActive.value.value : "");
      formData.append("page", currentPage.value);
      const res = await axios.post("profiles/search", formData);
      totalProducts.value = res.data.count;
      return res.data.data;
    } catch {
      alert("unable to load the data");
    } finally {
      isLoading.value = false;
    }
  };

  const updateProfileStatus = async (id, status) => {
    try {
      const formData = new FormData();
      formData.append("isActive", status);
      const resp = await axios.post(`/profiles/${id}/updateStatus`, formData);
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response != "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new category",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  watch([perPage, searchQuery, user, isActive, currentPage], () => {
    refProductsTable.value.refresh();
    //refetchData();
  });

  /* Search Users */
  const searchUsers = async (search, loading) => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", search);
      formData.append("perPage", 10);
      formData.append("page", 1);
      const response = await axios.post("/users/search", formData);
      const items = [];
      response.data.data.map((item) => {
        items.push({
          label: `${item.username} - ${item.userType}`,
          value: item._id,
        });
      });

      users.value = items;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response != "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new category",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value ? refProductsTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  return {
    searchUsers,
    statuses,
    users,
    user,
    searchQuery,
    isActive,
    refProductsTable,
    tableCols,
    loadProducts,
    perPageOptions,
    perPage,
    currentPage,
    dataMeta,
    totalProducts,
    updateProfileStatus,
    isLoading,
  };
}
